.login-bg {
    background-image: url(images/k2.jpg);
    /* height: 100vh; */
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    /* opacity: .6; */
    background-position: 50% 0;
    background-size: cover;
}

.css-1nnph2b-MuiButtonBase-root-MuiButton-root,
.css-1re7djo-MuiButtonBase-root-MuiButton-root,
.css-1s53qnj-MuiButtonBase-root-MuiButton-root,
.css-mwhv61-MuiButtonBase-root-MuiButton-root {
    text-transform: none !important;
}

.css-17injvq-MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
    color:#268dee !important;
}
.css-17injvq-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: #13e047 !important;
}

.textarea-container {
    position: relative;
}

.textarea-container.error-input textarea {
    border-color: red;
}

/* for mobile number with country code */
.phone-input-container {
    position: relative;
    width: 100%;
  }
  
  .floating-label {
    position: absolute;
    top: -8px;
    left: 40px;
    background-color: white;
    padding: 0 5px;
    font-size: 9px;
    color: gray;
    pointer-events: none;
    transition: all 0.2s;
  }

.disabled-date {
    pointer-events: none;
    color: #bdbdbd;
}
.super-app-theme--info-cell {
    color: #17a2b8; /* Replace with the desired "info" color */
}

.alert-success {
    background-color: gray;
    color: #ffffff; /* Set text color to white for better contrast */
    padding: 10px;
    border-radius: 4px;
}

.data-grid-container .MuiDataGrid-cell {
    white-space: normal !important;
  overflow: visible !important;
  text-overflow: unset !important;
  }
 